.placing {
  position: absolute;
  width: 100%;
}

.footer {
  max-height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #6b0000;
  opacity: 0.9;
  color: #ffffff;
  justify-content: start;
}

.footer p {
  padding: 5px;
  font-weight: normal;
  font-size: 18px;
}

.box_one {
  margin: 20px;
  justify-content: start;
  grid-template-columns: 3fr 1fr;
  text-align: left !important;
}

.box_one p {
  max-width: 300px;
}

.box_two {
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: start;
}

.box_two a:hover {
  text-decoration: underline;
}

.box_two a,
.box_two a:visited,
.box_two a:active{
  color: white !important;
}

.box_two a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 880px) {
  .box_two a:visited,
  .box_two a:active,
  .box_two a:hover {
    color: white !important;
    text-decoration: none !important;
  }
}
