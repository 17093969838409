.buttonStyle-about {
    color: white;
    background-color: #6b0000;
    border: none;
}

.buttonStyle-about h5 {
    color: white;
}

.card-body-about {
    color: white;
}

.cardDivider {
    margin: 20px;
}