* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

p {
  font-size: 20px;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  background-color: white;
  margin: 0 auto;
  width: 100%;
}

.navSpace {
  padding: 70px;
}

.flex-container {
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#bodyTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #6b0000;
}

#mainText {
  text-transform: uppercase;
  font-size: 32px;
  text-align: left;
}

#profile {
  max-width: 544px;
  margin: 50px auto;
}

#stepOut {
  min-height: 605px;
  max-width: 544px;
  margin: 5px auto;
}

#meSpeaking {
  width: 400px;
  height: 589px;
  position: absolute;
  z-index: 2;
}

#outBox {
  width: 398px;
  height: 398px;
  top: 211px;
  border-style: solid;
  border-width: 20px;
  border-color: #6b0000;
  z-index: 1;
  position: relative;
}

.youtube h5 {
  font-size: 16px;
  color: #6b0000;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.youtube h3 {
  font-size: 32px;
  text-align: left;
  margin: 20px auto;
}

.youtube p {
  margin-top: 20px;
}

.bar {
  background: white;
  display: block;
  height: 5px;
  border: 1px solid #6b0000;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 5px #6b0000;
}

.list {
  margin: 50px;
  padding-top: 50px;
}

.writing h5,
.speaking h5,
.companies h5,
.blog h5,
.hire h5,
.list h5 {
  font-size: 16px;
  color: #6b0000;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.writing h3,
.speaking h3,
.companies h3,
.blog h3,
.hire h3,
.list h3 {
  font-size: 32px;
  text-align: center;
  margin: 20px auto;
}

.youtube{
  padding: 50px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 10px;
  margin: 20px;
  text-align: center;
  align-items: center;
  justify-items: center;
}

.gridContainerVideo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  align-items: center;
  justify-items: center;
}

.grid-item-right-video {
  padding: 10px;
  min-width: 50%
}

.grid-item-left-video {
  padding: 10px;
  min-width: 50%;
}

#youtubevideo {
  width: 640px;
  height: 360px;
}

.writing{
  background-color: #ebebeb;
  padding: 50px;
}

.hire {
  padding: 100px;
}

.companies {
  padding: 100px;
}

.writing p,
.hire p {
  padding: 20px;
  font-size: 20px;
  margin: 0 auto;
  max-width: 1088px;
  text-align: center;
}

#research div {
  margin: 20px;
  border-style: solid;
  border-color: #6b0000;
  border-width: 3px;
  box-shadow: 10px 10px black;
}

.additional_writing {
  width: 250px;
  height: 250px;
}

#writing div,
.flexContainer div {
  width: 250px;
  height: 250px;
}

#writing div:hover {
  background-color: #ebebeb;
}

#writing h5 {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin-top: 45%;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;
  color: black;
  text-transform: uppercase;
}

#writing a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

.flexContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.company_boxes {
  margin: 20px;
}

a:hover,
a:visited,
a:active {
  color: black;
  text-decoration: none;
}

.flexContainer #text_box {
  position: absolute;
  margin-top: -250px;
  background-color: #ebebeb;
  box-shadow: 2px 2px 2px 2px #888888;
  opacity: 0.9;
}

.flexContainer #text_box p {
  text-align: center;
  opacity: 1;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hire {
  margin: 0 auto;
  align-content: center;
  background-color: #ebebeb;
}

.buttonTouch, .buttonTouchOne {
  background-color: white;
  border-style: solid;
  border-width: 3px;
  border-color: #6b0000;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  width: 150px;
  border-radius: 20px;
}

.buttonTouchOne {
  background-color: #ebebeb;
}

.buttonTouch h5, .buttonTouchOne h5 {
  color: #6b0000;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonTouch:hover h5 {
  color: white;
}

.buttonTouchOne:hover h5 {
  color: #ebebeb;
}

#get-in-touch-button {
  background-color: #ebebeb;
}

.buttonTouch:hover, .buttonTouchOne:hover, #get-in-touch-button:hover {
  background-color: #6b0000;
}

@media only screen and (max-width: 880px) {
  .youtube,
  .writing,
  .speaking,
  .hire,
  .list,
  #profile {
    padding: 20px;
  }

  .companies {
    padding: 40px;
    margin: 0px;
  }

  #writing div {
    width: 200px;
    height: 200px;
    justify-content: center;
  }

  #writing h5 {
    margin-top: 40%;
    padding: 5px;
    font-size: 16px;
  }

  #stepOut {
    min-height: 505px;
    max-width: 444px;
  }

  #meSpeaking {
    width: 300px;
    height: 441px;
  }

  #outBox {
    width: 292px;
    height: 292px;
    top: 168px;
  }

  #youtubevideo {
    width: auto;
    height: auto;
    margin: 1px;
  }

  .writing p, .hire p {
    text-align: left;
  }
}
