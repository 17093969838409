.navbar {
  width: 100%;
  box-shadow: 0 1em 0.5em -0.8em #6b0000;
  background-color: white;
  position: fixed;
  z-index: 100;
  left: 0%;
  transition-duration: 2s;
}

.gridbox {
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "a b";
}

.gridboxone {
  grid-area: a;
  text-align: start;
}

.gridboxtwo {
  grid-area: b;
  text-align: end;
  border: none;
  margin-top: -8px;
}

.menu {
  transition-duration: 5s;
}

.menu button {
  color: black;
  font-size: 24px;
  padding-right: 10px;
  padding-left: 10px;
  opacity: 1;
  border: none;
  background:none;
  margin: 4px;
  margin-top: 16px;
}

.menu button:hover {
  text-decoration: underline;
  animation-duration: 1s;
}

a, a:active, a:hover {
  color: black;
}

#signature {
  max-height: 60px;
}


