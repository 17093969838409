* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

.wrapper {
  height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -112px;
}

.push {
  height: 200px;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  background-color: white;
  margin: 0 auto;
}

.contact {
  max-width: 544px;
  margin: 0 auto;
}

.contact h5 {
  font-size: 16px;
  color: #6b0000;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
