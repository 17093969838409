body {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  background-color: white;
  margin: 0 auto;
  transition: .5s ease;
  width: 100%;
}

a:visited,
a:active,
a:hover {
  color: inherit !important;
  text-decoration: none;
}

.snowImage {
  min-width: 160px;
  min-height: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.topImage {
  background-color: #ebebeb;
  padding: 50px;
}

.myStory {
  padding: 100px;
}

#presenting {
  width: 715px;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 50px;
}

.statistics div {
  margin: auto auto;
}

.counter {
  font-size: 50px;
  font-weight: bold;
  position: relative;
  top: 50%;
  left: 40%;
}

.hobbies h5,
.statistics h5,
.confGallery h5,
.mentoring h5,
.foodGallery h5,
.myStory h5 {
  font-size: 16px;
  margin: 10px auto;
  color: #6b0000;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.hobbies h3,
.statistics h3,
.confGallery h3,
.mentoring h3,
.foodGallery h3,
.topImage h3,
.myStory h3 {
  font-size: 32px;
  text-align: center;
  margin: 20px auto;
}

.topImage h3 {
  margin-top: 50px;
  margin-bottom: 80px;
}

.confGallery {
  margin-top: 50px;
}

.mentoring {
  background-color: #ebebeb;
  padding: 100px;
}

.gridContainer p {
  font-size: 18px;
  margin: auto auto;
  max-width: 1088px;
}

.buttonTouchAbout {
  background-color: white;
  border-style: solid;
  border-width: 3px;
  border-color: #6b0000;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  width: 150px;
  border-radius: 20px;
}

.buttonTouchAbout {
  background-color: #ebebeb;
}

.buttonTouchAbout h5 {
  color: #6b0000;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  position: relative;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonTouchAbout:hover h5 {
  color: #ebebeb;
}

.buttonTouchAbout:hover {
  background-color: #6b0000;
}

.gridContainer {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  grid-gap: 10px;
  margin: 20px;
  text-align: center;
  align-items: center;
  justify-items: center;
}

.grid-item-left {
  display: inline-grid;
  justify-items: left;
  align-items: left;
  text-align: left;
}

.grid-item-left p {
  text-align: left;
}

.grid-item-right {
  width: 80%;
  align-content: center;
  margin: auto auto;
}

.hobbies {
  padding: 100px;
}

#hobbies div {
  border-style: solid;
  border-color: black;
  border-width: 3px;
  width: 198px;
  height: 298px;
  margin: 10px;
  background-color: white;
  align-items: center;
}

#hobbies h5 {
  position: relative;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  margin: auto auto;
  text-transform: uppercase;
  font-weight: bold;
}

#hobbies #cooking:hover {
  background-image: url("./extras/image/general/cooking.png");
  background-repeat: no-repeat;
  transition: .5s ease;
}

#hobbies #cooking:hover h5 {
  opacity: 0;
}

#hobbies #reading:hover {
  background-image: url("./extras/image/general/reading.png");
  background-repeat: no-repeat;
  transition: .5s ease;
}

#hobbies #reading:hover h5 {
  opacity: 0;
}

#hobbies #travelling:hover {
  background-image: url("./extras/image/general/travelling.png");
  background-repeat: no-repeat;
  transition: .5s ease;
}

#hobbies #travelling:hover h5 {
  opacity: 0;
}

#hobbies #sports:hover {
  background-image: url("./extras/image/general/sports.png");
  background-repeat: no-repeat;
  transition: .5s ease;
}

#hobbies #sports:hover h5 {
  opacity: 0;
}

@media only screen and (max-width: 520px) {

  #presenting {
    width: 320px;
  }

  #hobbies div {
    min-width: 198px;
    min-height: 298px;
  }

  .gridContainer {
    margin: 8px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    padding-bottom: 32px;
  }

  .grid-item-left p {
    padding-left: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
  
  .myStory, .mentoring, .hobbies {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 1440px) {

  #presenting {
    max-width: 600px;;
  }

  .gridContainer {
    margin: 8px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    padding-bottom: 32px;
  }

  .myStory, .mentoring, .hobbies {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
