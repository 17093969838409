.experience {
  padding-top: 50px;
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  background-color: white;
  margin: 0 auto;
  text-align: center;
}

.experience img {
    max-width: 180px;
    margin: 16px;
}

.linkedIn .buttonTouch:hover {
  background-color: #6b0000;
}

.subtitle {
    color: #6b0000;
    font-weight: bold;
}